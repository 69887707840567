<template>
    <Index />
  </template>
  
  <script>
  import Index from "../components/Login.vue";
  
  export default {
    name: "Admin",
    components: {
      Index,
    },
  };
  </script>
  
  <style></style>
  