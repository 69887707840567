<template>
  <Index />
</template>

<script>
import Index from "../components/Dashboard.vue";

export default {
  name: "Dashboard",
  components: {
    Index,
  },
};
</script>

<style></style>
