<template>
  <Index />
</template>

<script>
import Index from "../components/Index.vue";

export default {
  name: "Home",
  components: {
    Index,
  },
};
</script>

<style></style>
